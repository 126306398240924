<template>
    <div :class="size">
        <v-card>
            <v-card-title>
                Gmail
            </v-card-title>
            <div class="wrapper">
                <v-list two-line class="pt-0">
                    <v-list-item-group>
                            <template v-for="index in 10">
                                <v-list-item :key="'A-' + index" class="selectedItem">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold">David STONES</v-list-item-title>
                                        <v-list-item-subtitle class="text--primary">Entretien d'embauche</v-list-item-subtitle>
                                        <v-list-item-subtitle>Bonjour Monsieur DOE, Je me permets de vous contacter concerant l'entretien d'embauche prévu ce mardi.</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-list-item-action-text>15h32</v-list-item-action-text>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                    </v-list-item-group>
                </v-list>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    name : 'Gmail',
    data () {
        return {
            tab: null,
        }
    },
    props : {
        size : {
            type : String,
            default : 'md',
        }
    },
}
</script>

<style lang="scss" scoped>
.widget {
    @media screen and (max-width: 600px) {
        grid-column: span 2;
        grid-row: span 3;   
    }
    @media screen and (min-width: 600px) {
        grid-column: span 2;
        grid-row: span 4;
    }
    @media screen and (min-width: 1200px) {
        &.sm {
            grid-column: span 2;
            grid-row: span 4;
        }
        &.md {
            grid-column: span 2;
            grid-row: span 6;
        }
        &.lg {
            grid-column: span 4;
            grid-row: span 4;
        }
        &.xl {
            grid-column: span 4;
            grid-row: span 6;
        }
    }
}

.selectedItem {
    ::before {
        content: "";
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 0.4em;
        background-color: rgb(0, 153, 255);
    }

    &:hover {
        ::before {
            opacity: 1;
        }      
    }
}
</style>

